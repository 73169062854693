import React from 'react';
import {useOutlet} from 'reconnect.js';
import CheckoutInfoPage from 'rev.sdk.js/Templates/CheckoutInfoPage';
import renderExtraCheckoutSummary from '../../Utils/renderExtraCheckoutSummary';

function CheckoutInfo(props) {
  useOutlet('store');

  return (
    <CheckoutInfoPage
      forceSameAsBuyer
      checkoutSummaryProps={{renderExtraCheckoutSummary}}
      {...props}
    />
  );
}

export default CheckoutInfo;
