import React from 'react';
import {getOutlet} from 'reconnect.js';

export default function renderExtraCheckoutSummary({cart}) {
  const store = getOutlet('store').getValue();
  const elems = [];

  if (cart.payment_subtype === 'offline') {
    elems.push(
      <h4 key="offline-hint">
        *
        溫馨提醒：若您的訂單選擇ATM，請記得於「3天內」完成匯款，並在「我的訂單」-
        {'>'}
        「訂單詳情」內更新匯款後五碼資訊以便核對，避免訂單自動被取消。
      </h4>,
    );
  }

  const selfPickAddr = store?.config?.store_self_pick_address;
  if (selfPickAddr && cart.logistics_type === 'SELF_PICK') {
    elems.push(<h4 key="self-pick-addr">* 自取地址: {selfPickAddr}</h4>);
  }

  if (elems.length > 0) {
    return <div style={{margin: '10px 0'}}>{elems}</div>;
  }

  return null;
}
